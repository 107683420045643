import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import gameSlice from "./features/game/gameSlice";
import imageSlice from "./features/image/imageSlice";
import mentorSlice from "./features/mentor/mentorSlice";
import eventSlice from "./features/event/eventSlice";
import newsSlice from "./features/news/newsSlice";
import blogSlice from "./features/blog/blogSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    game: gameSlice,
    image: imageSlice,
    mentor: mentorSlice,
    event: eventSlice,
    news: newsSlice,
    blog: blogSlice,
  },
});
