import axios from "../../utils/axios";
import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { removeBlog } from "../../redux/features/blog/blogSlice";
import { toast } from "react-toastify";

function BlogAdminPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [blog, setBlog] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const removeBlogHandler = () => {
    try {
      dispatch(removeBlog(params.id));
      toast("Мероприятие удалено");
      navigate("/blogs");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBlog = async () => {
    const { data } = await axios.get(`/blogs/${params.id}`);
    setBlog(data);

    window.VK.Widgets.Article(
      `vk_article_-217632014_${data.postNumber}`,
      `${data.postName}`
    );
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  if (!blog) {
    return (
      <div className="text-xl text-center text-black py-10">
        Статья не существует.
      </div>
    );
  }

  const PostNumber = `vk_article_-217632014_${blog.postNumber}`;

  return (
    <div className="max-w-[900px] mx-auto py-10">
      <div className="flex justify-between">
        <h2 className="text-2xl text-[#40627A]">Карточка статьи</h2>
        <Link
          to={"/blogs"}
          className="text-xs rounded-sm py-2 px-4 bg-[#40627A] text-white mb-5"
        >
          Назад
        </Link>
      </div>
      <div className="flex gap-10">
        <div className="flex rounded-sm h-40 basis-1/4">
          <div id={PostNumber}></div>
        </div>
        <div className="flex flex-col gap-3 basis-3/4">
          <div>
            <p className="text-gray-400 text-xs">Назвние статьи</p>
            <div className="text-black text-l">{blog.title}</div>
          </div>
          <div>
            <p className="text-gray-400 text-xs">Номер статьи</p>
            <p className="text-black text-l">{blog.postNumber}</p>
          </div>
          <div>
            <p className="text-gray-400 text-xs">Ссылка на статью</p>
            <p className="text-black text-l">{blog.postName}</p>
          </div>
          <div className="flex gap-20 mt-10">
            <Link
              to={`/blogs/${params.id}/edit`}
              className="flex items-center justify-center gap-2 text-s hover:text-gray-400"
            >
              <AiFillEdit />
              <span>Редактировать</span>
            </Link>
            <button
              onClick={openModal}
              className="flex items-center justify-center gap-2 text-s text-red-600 hover:text-red-400"
            >
              <AiFillDelete />
              <span>Удалить</span>
            </button>
          </div>
          {/* Модальное окно */}
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Удалить статью?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Вы действительно хотите удалить данную статью?
                        </p>
                      </div>

                      <div className="mt-4 flex justify-between">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Отмена
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                          onClick={removeBlogHandler}
                        >
                          Удалить
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    </div>
  );
}

export default BlogAdminPage;
