import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  mentors: [],
  loading: false,
};

// функция, отвечающая за создание ментора
export const createMentor = createAsyncThunk(
  "mentor/createMentor",
  async (params) => {
    try {
      const { data } = await axios.post("/mentors", params);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за получение всех менторов
export const getAllMentors = createAsyncThunk(
  "mentor/getAllMentors",
  async () => {
    try {
      const { data } = await axios.get("/mentors");
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за редактирование ментора
export const updateMentor = createAsyncThunk(
  "mentor/updateMentor",
  async (updatedMentor) => {
    try {
      const { data } = await axios.put(
        `/mentors/${updatedMentor.get("id")}`,
        updatedMentor
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за удаление ментора
export const removeMentor = createAsyncThunk(
  "mentor/removeMentor",
  async (id) => {
    try {
      await axios.delete(`/mentors/${id}`, id);
      return { id: id };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const mentorSlice = createSlice({
  name: "mentor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // создание ментора
      .addCase(createMentor.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMentor.fulfilled, (state, action) => {
        state.loading = false;
        state.mentors.push(action.payload);
      })
      .addCase(createMentor.rejected, (state) => {
        state.loading = false;
      })
      // получение всех менторов
      .addCase(getAllMentors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMentors.fulfilled, (state, action) => {
        state.loading = false;
        state.mentors = action.payload.mentors;
      })
      .addCase(getAllMentors.rejected, (state) => {
        state.loading = false;
      })
      // редактирование ментора
      .addCase(updateMentor.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMentor.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.mentors.findIndex(
          // в action.payload лежит обновленный ментор, которого мы получили на бэке,
          // поэтому находим индекс ментора у которого старый id совпадает с id измененного ментора
          (mentor) => mentor.id === action.payload.id
        );
        // после того, как определили индекс элемента, мы меняем этот элемент на обновленный
        state.mentors[index] = action.payload;
      })
      .addCase(updateMentor.rejected, (state) => {
        state.loading = false;
      })
      // удаление ментора
      .addCase(removeMentor.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeMentor.fulfilled, (state, action) => {
        state.loading = false;
        state.mentors = state.mentors.filter(
          // возвращаем перечень менторов без удаленного ментора
          (mentor) => mentor.id != action.payload.id
        );
      })
      .addCase(removeMentor.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default mentorSlice.reducer;
