import React from "react";

function Dropdown(props) {
  const handleSelect = (event) => {
    props.onSelect(event.target.value);
  };

  const options = props.options.map((option) => (
    <option
      key={option.value}
      value={option.value}
      selected={props.selected === option.value}
    >
      {option.label}
    </option>
  ));

  return (
    <select
      className="mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none resize-none"
      onChange={handleSelect}
    >
      {options}
    </select>
  );
}

export default Dropdown;
