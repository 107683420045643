import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  games: [],
  loading: false,
};

// функция, отвечающая за создание игры
export const createGame = createAsyncThunk(
  "game/createGame",
  async (params) => {
    try {
      const { data } = await axios.post("/games", params);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за получение всех игр
export const getAllGames = createAsyncThunk("game/getAllGames", async () => {
  try {
    const { data } = await axios.get("/games");
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

// функция, отвечающая за редактирование игры
export const updateGame = createAsyncThunk(
  "game/updateGame",
  async (updatedGame) => {
    try {
      const { data } = await axios.put(
        `/games/${updatedGame.get("id")}`,
        updatedGame
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за удаление игры
export const removeGame = createAsyncThunk("game/removeGame", async (id) => {
  try {
    await axios.delete(`/games/${id}`, id);
    return { id: id };
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // создание игры
      .addCase(createGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGame.fulfilled, (state, action) => {
        state.loading = false;
        state.games.push(action.payload);
      })
      .addCase(createGame.rejected, (state) => {
        state.loading = false;
      })
      // получение всех игр
      .addCase(getAllGames.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        state.loading = false;
        state.games = action.payload.games;
      })
      .addCase(getAllGames.rejected, (state) => {
        state.loading = false;
      })
      // редактирование игры
      .addCase(updateGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGame.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.games.findIndex(
          // в action.payload лежит обновленная игра, которую мы получили на бэке,
          // поэтому находим индекс игры у которой старый id совпадает с id измененной игры
          (game) => game.id === action.payload.id
        );
        // после того, как определили индекс элемента, мы меняем этот элемент на обновленный
        state.games[index] = action.payload;
      })
      .addCase(updateGame.rejected, (state) => {
        state.loading = false;
      })
      // удаление игры
      .addCase(removeGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeGame.fulfilled, (state, action) => {
        state.loading = false;
        state.games = state.games.filter(
          // возвращаем перечень постов без удаленного поста
          (game) => game.id !== action.payload.id
        );
      })
      .addCase(removeGame.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default gameSlice.reducer;
