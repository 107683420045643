import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from "react";

// Pages
import LayoutAdmin from "./components/LayoutAdmin";
import { RegisterAdminPage } from "./pages/RegisterAdminPage";
import LoginAdminPage from "./pages/LoginAdminPage";

import GamesAdminPage from "./pages/GamesAdminPage";
import GameAdminPage from "./pages/GameAdminPage";
import AddGamesAdminPage from "./pages/AddGamesAdminPage";
import EditGamesAdminPage from "./pages/EditGamesAdminPage";

import MentorsAdminPage from "./pages/Mentors/MentorsAdminPage";
import MentorAdminPage from "./pages/Mentors/MentorAdminPage";
import EditMentorsAdminPage from "./pages/Mentors/EditMentorsAdminPage";
import AddMentorsAdminPage from "./pages/Mentors/AddMentorsAdminPage";

import EventsAdminPage from "./pages/Events/EventsAdminPage";
import EventAdminPage from "./pages/Events/EventAdminPage";
import EditEventsAdminPage from "./pages/Events/EditEventsAdminPage";
import AddEventsAdminPage from "./pages/Events/AddEventsAdminPage";

import NewsAdminPage from "./pages/News/NewsAdminPage";
import SingleNewsAdminPage from "./pages/News/SingleNewsAdminPage";
import EditNewsAdminPage from "./pages/News/EditNewsAdminPage";
import AddNewsAdminPage from "./pages/News/AddNewsAdminPage";

import BlogsAdminPage from "./pages/Blogs/BlogsAdminPage";
import BlogAdminPage from "./pages/Blogs/BlogAdminPage";
import EditBlogsAdminPage from "./pages/Blogs/EditBlogsAdminPage";
import AddBlogsAdminPage from "./pages/Blogs/AddBlogsAdminPage";
//
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordChangeAdminPage from "./pages/PasswordChangeAdminPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LayoutAdmin />,
      children: [
        {
          path: "games",
          element: <GamesAdminPage />,
        },
        {
          path: "games/:id",
          element: <GameAdminPage />,
        },
        {
          path: "games/:id/edit",
          element: <EditGamesAdminPage />,
        },
        {
          path: "games/new",
          element: <AddGamesAdminPage />,
        },
        {
          path: "mentors",
          element: <MentorsAdminPage />,
        },
        {
          path: "mentors/:id",
          element: <MentorAdminPage />,
        },
        {
          path: "mentors/:id/edit",
          element: <EditMentorsAdminPage />,
        },
        {
          path: "mentors/new",
          element: <AddMentorsAdminPage />,
        },
        {
          path: "events",
          element: <EventsAdminPage />,
        },
        {
          path: "events/:id",
          element: <EventAdminPage />,
        },
        {
          path: "events/:id/edit",
          element: <EditEventsAdminPage />,
        },
        {
          path: "events/new",
          element: <AddEventsAdminPage />,
        },
        {
          path: "news",
          element: <NewsAdminPage />,
        },
        {
          path: "news/:id",
          element: <SingleNewsAdminPage />,
        },
        {
          path: "news/:id/edit",
          element: <EditNewsAdminPage />,
        },
        {
          path: "news/new",
          element: <AddNewsAdminPage />,
        },
        {
          path: "blogs",
          element: <BlogsAdminPage />,
        },
        {
          path: "blogs/:id",
          element: <BlogAdminPage />,
        },
        {
          path: "blogs/:id/edit",
          element: <EditBlogsAdminPage />,
        },
        {
          path: "blogs/new",
          element: <AddBlogsAdminPage />,
        },
        // {
        //   path: "register",
        //   element: <RegisterAdminPage />,
        // },
        {
          path: "login",
          element: <LoginAdminPage />,
        },
        {
          path: "security",
          element: <PasswordChangeAdminPage />,
        },
      ],
    },
  ]);

  return (
    <React.Fragment>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
      <ToastContainer position="bottom-right" />
    </React.Fragment>
  );
}

export default App;
