import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkIsAuth, loginUser } from "../redux/features/auth/authSlice";
import { toast } from "react-toastify";

function LoginAdminPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { status } = useSelector((state) => state.auth);
  const isAuth = useSelector(checkIsAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (status) toast(status);

    if (isAuth) navigate("/games");
  }, [status, isAuth, navigate]);

  const handelSubmit = () => {
    try {
      dispatch(loginUser({ username, password }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="w-1/4 h-60 mx-auto mt-40"
    >
      <h1 className="text-lg text-black text-center">Авторизация</h1>
      <label className="text-xs text-gray-400">
        Логин:
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Введите логин"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
      </label>
      <label className="text-xs text-gray-400">
        Пароль:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Введите пароль"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
      </label>

      <div className="flex gap-8 justify-center mt-4">
        <button
          type="submit"
          onClick={handelSubmit}
          className="flex justify-center items-center text-xs bg-gray-600 text-white rounded-sm py-2 px-4"
        >
          Войти
        </button>
      </div>
    </form>
  );
}

export default LoginAdminPage;
