import React, { useEffect } from "react";
import MentorItem from "../../components/MentorItem";
import { useDispatch, useSelector } from "react-redux";
import { getAllMentors } from "../../redux/features/mentor/mentorSlice";
import { AddButton } from "../../components/AddButton";
import { Link } from "react-router-dom";

function MentorsAdminPage() {
  const dispatch = useDispatch();
  const mentors = useSelector((state) => state.mentor.mentors);

  useEffect(() => {
    dispatch(getAllMentors());
  }, [dispatch]);

  return (
    <div className="max-w-[900px] mx-auto py-10">
      <div className="flex justify-between">
        <h1 className="text-3xl text-[#40627A]">Состав команды</h1>
        <Link to={"/mentors/new"} className="mb-5">
          <AddButton />
        </Link>
      </div>

      {mentors.length > 0 ? (
        <div className="flex flex-col basis-4/5">
          {mentors.map((mentor) => (
            <MentorItem key={mentor.id} mentor={mentor} />
          ))}
        </div>
      ) : (
        <div className="text-xl text-center text-black py-40">
          Члены команды отсутствуют.
        </div>
      )}
    </div>
  );
}

export default MentorsAdminPage;
