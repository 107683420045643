import React, { useEffect } from "react";
import GameItem from "../components/GameItem";
import { useDispatch, useSelector } from "react-redux";
import { getAllGames } from "../redux/features/game/gameSlice";
import { AddButton } from "../components/AddButton";
import { Link } from "react-router-dom";

function GamesAdminPage() {
  const dispatch = useDispatch();
  const games = useSelector((state) => state.game.games);

  useEffect(() => {
    dispatch(getAllGames());
  }, [dispatch]);

  return (
    <div className="max-w-[900px] mx-auto py-10">
      <div className="flex justify-between">
        <h1 className="text-3xl text-[#40627A]">Продукция</h1>
        <Link to={"/games/new"} className="mb-5">
          <AddButton />
        </Link>
      </div>

      {games.length > 0 ? (
        <div className="flex flex-col basis-4/5">
          {games.map((game) => (
            <GameItem key={game.id} game={game} />
          ))}
        </div>
      ) : (
        <div className="text-xl text-center text-black py-40">
          Продукция отсутствует.
        </div>
      )}
    </div>
  );
}

export default GamesAdminPage;
