import React, { useEffect } from "react";
import NewsItem from "../../components/NewsItem";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "../../redux/features/news/newsSlice";
import { AddButton } from "../../components/AddButton";
import { Link } from "react-router-dom";

function NewsAdminPage() {
  const dispatch = useDispatch();
  const news = useSelector((state) => state.news.news);

  useEffect(() => {
    dispatch(getAllNews());
  }, [dispatch]);

  return (
    <div className="max-w-[900px] mx-auto py-10">
      <div className="flex justify-between">
        <h1 className="text-3xl text-[#40627A]">Новости</h1>
        <Link to={"/news/new"} className="mb-5">
          <AddButton />
        </Link>
      </div>

      {news.length > 0 ? (
        <div className="flex flex-col basis-4/5">
          {news.map((news) => (
            <NewsItem key={news.id} news={news} />
          ))}
        </div>
      ) : (
        <div className="text-xl text-center text-black py-40">
          Новости отсутствуют.
        </div>
      )}
    </div>
  );
}

export default NewsAdminPage;
