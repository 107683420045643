import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { checkIsAuth, getMe } from "../redux/features/auth/authSlice";

const ProtectedWrapper = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = useSelector(checkIsAuth);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    checkUserToken();
  }, [isAuth, isLoading, location]);

  const checkUserToken = () => {
    const loginPage = location.pathname.includes("login");

    if (!isAuth && !loginPage) {
      setIsLoggedIn(false);
      if (!isLoading) {
        navigate("/login");
      }
    } else {
      setIsLoggedIn(true);
    }
  };

  return <React.Fragment>{isLoggedIn ? <Outlet /> : null}</React.Fragment>;
};
export default ProtectedWrapper;
