import React, { Fragment, useState } from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { removeMentor } from "../redux/features/mentor/mentorSlice";
import { unwrapResult } from "@reduxjs/toolkit";

function MentorItem({ mentor }) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const removeMentorHandler = () => {
    dispatch(removeMentor(mentor.id))
      .then(unwrapResult)
      .then(() => {
        toast("Член команды удален");
        setIsOpen(false);
      })
      .catch(() => {
        toast("Ошибка! Перезагрузите страницу и попробуйте заново.");
        setIsOpen(false);
      });
  };

  return (
    <div className="flex justify-between basis-1/4 flex-grow pt-4 pb-4 border-t-2 border-solid border-slate-300">
      <Link
        to={`/mentors/${mentor.id}`}
        className="flex basis-11/12 gap-10 items-center"
      >
        <div className="flex rounded-sm h-40 basis-1/4">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${mentor.previewImg}`}
            alt="img"
          />
        </div>
        <div className="flex basis-1/4 text-black text-xl ">
          {mentor.fullName}
        </div>
        <div className="flex basis-1/4 text-black text-xl ">
          {mentor.role === "Другое" ? mentor.otherRole : mentor.role}
        </div>
      </Link>
      <div className="flex gap-10 items-center">
        <button>
          <Link to={`/mentors/${mentor.id}/edit`}>
            <AiFillEdit className="hover:text-gray-400" />
          </Link>
        </button>
        <button onClick={openModal}>
          <AiFillDelete className="hover:text-gray-400" />
        </button>
      </div>
      {/* Модальное окно */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Удалить члена команды?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Вы действительно хотите удалить данного члена команды?
                    </p>
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Отмена
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={removeMentorHandler}
                    >
                      Удалить
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default MentorItem;
