import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  blogs: [],
  loading: false,
};

// функция, отвечающая за создание поста
export const createBlog = createAsyncThunk(
  "blog/createBlog",
  async (params) => {
    try {
      const { data } = await axios.post("/blogs", params);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за получение всех постов
export const getAllBlogs = createAsyncThunk("blog/getAllBlogs", async () => {
  try {
    const { data } = await axios.get("/blogs");
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

// функция, отвечающая за редактирование поста
export const updateBlog = createAsyncThunk(
  "blog/updateMentor",
  async (updatedBlog) => {
    try {
      const { data } = await axios.put(
        `/blogs/${updatedBlog.get("id")}`,
        updatedBlog
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за удаление поста
export const removeBlog = createAsyncThunk(
  "blogs/removeBlog",
  async (id) => {
    try {
      await axios.delete(`/blogs/${id}`, id);
      return { id: id };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // создание поста
      .addCase(createBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs.push(action.payload);
      })
      .addCase(createBlog.rejected, (state) => {
        state.loading = false;
      })
      // получение всех постов
      .addCase(getAllBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload.blogs;
      })
      .addCase(getAllBlogs.rejected, (state) => {
        state.loading = false;
      })
      // редактирование поста
      .addCase(updateBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.blogs.findIndex(
          (mentor) => mentor.id === action.payload.id
        );
        state.blogs[index] = action.payload;
      })
      .addCase(updateBlog.rejected, (state) => {
        state.loading = false;
      })
      // удаление поста
      .addCase(removeBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = state.blogs.filter(
          // возвращаем перечень постов без удаленного поста
          (blog) => blog.id !== action.payload.id
        );
      })
      .addCase(removeBlog.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default blogSlice.reducer;
