import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  events: [],
  loading: false,
};

// функция, отвечающая за создание мероприятия
export const createEvent = createAsyncThunk(
  "event/createEvent",
  async (params) => {
    try {
      const { data } = await axios.post("/events", params);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за получение всех мероприятий
export const getAllEvents = createAsyncThunk("event/getAllEvents", async () => {
  try {
    const { data } = await axios.get("/events");
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

// функция, отвечающая за редактирование мероприятия
export const updateEvent = createAsyncThunk(
  "event/updateMentor",
  async (updatedEvent) => {
    try {
      const { data } = await axios.put(
        `/events/${updatedEvent.get("id")}`,
        updatedEvent
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за удаление мероприятия
export const removeEvent = createAsyncThunk(
  "events/removeEvent",
  async (id) => {
    try {
      await axios.delete(`/events/${id}`, id);
      return { id: id };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // создание мероприятия
      .addCase(createEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events.push(action.payload);
      })
      .addCase(createEvent.rejected, (state) => {
        state.loading = false;
      })
      // получение всех мероприятий
      .addCase(getAllEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload.events;
      })
      .addCase(getAllEvents.rejected, (state) => {
        state.loading = false;
      })
      // редактирование мероприятия
      .addCase(updateEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.events.findIndex(
          (mentor) => mentor.id === action.payload.id
        );
        state.events[index] = action.payload;
      })
      .addCase(updateEvent.rejected, (state) => {
        state.loading = false;
      })
      // удаление мероприятия
      .addCase(removeEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events = state.events.filter(
          // возвращаем перечень мероприятий без удаленного мероприятия
          (event) => event.id !== action.payload.id
        );
      })
      .addCase(removeEvent.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default eventSlice.reducer;
