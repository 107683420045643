import React, { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateEvent } from "../../redux/features/event/eventSlice";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import ImageUpload from "../../components/ImageUpload";
import { useForm } from "react-hook-form";
import {
  getImages,
  removeMarkedImages,
} from "../../redux/features/image/imageSlice";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ruLocale from "date-fns/locale/ru";

function EditEventsAdminPage() {
  const [previewImg, setPreview] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  registerLocale("ru", ruLocale);
  setDefaultLocale("ru");

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = useCallback(async () => {
    const { data } = await axios.get(`/events/${params.id}`);

    setSelectedDate(new Date(data.date));
    
    setPreview(data.previewImg);
    dispatch(getImages([data.previewImg]));
    initCounters(data);
    reset(data);
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValue("date", date);
  };

  const onSubmit = (data) => {
    try {
      if (!previewImg) {
        toast("Загрузите фото мероприятия");
        return;
      }

      const updatedformData = new FormData();
      updatedformData.append("id", data.id);
      updatedformData.append("title", data.title);
      updatedformData.append("description", data.description);
      updatedformData.append("date", data.date);
      updatedformData.append("place", data.place);
      updatedformData.append("previewImg", previewImg);
      dispatch(updateEvent(updatedformData));
      dispatch(removeMarkedImages());
      toast("Изменения сохранены");
      navigate("/events");
    } catch (error) {
      console.log(error);
    }
  };

  const [counters, setCounters] = useState({
    title: 0,
    description: 0,
    place: 0,
  });

  const initCounters = (data) => {
    setCounters((prevState) => {
      return {
        title: data.title.length,
        description: data.description.length,
        place: data.place.length,
      };
    });
  };

  // счетчик символов
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCounters((prevCounters) => ({
      ...prevCounters,
      [name]: value.length,
    }));
  };

  const returnHandler = () => {
    navigate("/events");
  };

  const previewChangeHandler = (preview) => {
    setPreview(preview);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-5/12 mx-auto py-10">
      <p className="text-xl mb-5 ">Редактирование мероприятия</p>
      <label className="text-xs text-black opacity-70">
        Название мероприятия*
        <input
          {...register("title", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите название мероприятия"
          onChange={handleInputChange}
          name="title"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.title && errors.title.message && (
              <p className="text-red-600">{errors.title.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.title}/50</p>
        </div>
      </label>

      <div className="mt-5 text-xs text-black opacity-70">
        Краткое описание*
        <textarea
          {...register("description", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 200,
              message:
                "Превышена максимальная длина символов (200). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите краткое описание"
          rows="2"
          onChange={handleInputChange}
          name="description"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.description && errors.description.message && (
              <p className="text-red-600">{errors.description.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.description}/200</p>
        </div>
      </div>

      <div className="text-xs text-black opacity-70">
        <div>
          <span className="pr-4">Дата и время проведения*</span>
          <DatePicker
            {...register("date", {
              required: "Поле обязательно для заполнения",
            })}
            selected={selectedDate}
            onChange={handleDateChange}
            name="date"
            placeholderText="Выберите дату"
            className="mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500"
            dateFormat="yyyy-MM-dd HH:mm"
            showTimeSelect
            timeFormat="HH:mm"
            popperPlacement="top"
            popperClassName="bg-white opacity-100"
          />
        </div>
        <div>
          {errors.date && errors.date.message && (
            <p className="text-red-600">{errors.date.message}</p>
          )}
        </div>
      </div>

      <div className="text-xs text-black opacity-70  mt-8">
        Место провидения*
        <input
          {...register("place", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 100,
              message:
                "Превышена максимальная длина символов (100). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите место провидения"
          onChange={handleInputChange}
          name="place"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.place && errors.place.message && (
              <p className="text-red-600">{errors.place.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.place}/100</p>
        </div>
      </div>

      <ImageUpload
        preview={previewImg}
        onSelect={previewChangeHandler}
        delayedRemove={true}
        multipleImages={false}
      />

      <div className="flex gap-8 items-center justify-end mt-4">
        <button
          type="submit"
          className="flex justify-center items-center bg-green-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Сохранить изменения
        </button>

        <button
          onClick={returnHandler}
          className=" flex justify-center items-center bg-red-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Отменить
        </button>
      </div>
    </form>
  );
}

export default EditEventsAdminPage;
