import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  news: [],
  loading: false,
};

// функция, отвечающая за создание новости
export const createNews = createAsyncThunk(
  "news/createNews",
  async (params) => {
    try {
      const { data } = await axios.post("/news", params);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за получение всех новостей
export const getAllNews = createAsyncThunk("news/getAllNews", async () => {
  try {
    const { data } = await axios.get("/news");
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

// функция, отвечающая за редактирование новости
export const updateNews = createAsyncThunk(
  "news/updateNews",
  async (updatedNews) => {
    try {
      const { data } = await axios.put(
        `/news/${updatedNews.get("id")}`,
        updatedNews
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// функция, отвечающая за удаление новости
export const removeNews = createAsyncThunk("news/removeNews", async (id) => {
  try {
    await axios.delete(`/news/${id}`, id);
    return { id: id };
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // создание новости
      .addCase(createNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news.push(action.payload);
      })
      .addCase(createNews.rejected, (state) => {
        state.loading = false;
      })
      // получение всех новостей
      .addCase(getAllNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload.news;
      })
      .addCase(getAllNews.rejected, (state) => {
        state.loading = false;
      })
      // редактирование новости
      .addCase(updateNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.news.findIndex(
          // в action.payload лежит обновленная новость, которую мы получили на бэке,
          // поэтому находим индекс новости у которой старый id совпадает с id измененной новости
          (news) => news.id === action.payload.id
        );
        // после того, как определили индекс элемента, мы меняем этот элемент на обновленный
        state.news[index] = action.payload;
      })
      .addCase(updateNews.rejected, (state) => {
        state.loading = false;
      })
      // удаление новости
      .addCase(removeNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = state.news.filter(
          // возвращаем перечень новостей без удаленной новости
          (news) => news.id !== action.payload.id
        );
      })
      .addCase(removeNews.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default newsSlice.reducer;
