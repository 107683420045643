import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  images: [],
  imageIdsToDelete: [],
  loading: false,
};

// функция, отвечающая за сохранение фотографии
export const saveImage = createAsyncThunk("image/saveImage", async (image) => {
  try {
    const formData = new FormData();
    formData.append("image", image);
    const { data } = await axios.post("/images", formData);
    const result = {
      name: image.name,
      id: data[0],
    };
    return result;
  } catch (error) {
    console.log(error);
  }
});

// функция, отвечающая за удаление изображения
export const removeImage = createAsyncThunk("image/removeImage", async (id) => {
  try {
    await axios.delete(`/images/${id}`, id);
    return {
      id: id,
    };
  } catch (error) {
    console.log(error);
  }
});

// функция, отвечающая за удаление изображения
export const removeMarkedImages = createAsyncThunk(
  "image/removeMarkedImages",
  async (_, { getState }) => {
    try {
      const imageIdsToDelete = getState().image.imageIdsToDelete;
      for (const id of imageIdsToDelete) {
        await axios.delete(`/images/${id}`, id);
      }
      return {
        ids: imageIdsToDelete,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

// функция, которая заменяет все текущие фотографии на переданные
export const getImages = createAsyncThunk("image/getImages", async (ids) => {
  try {
    const metaPromises = [];
    for (const id of ids) {
      metaPromises.push(
        axios.get(`/images/${id}/meta`, id).then((m) => {
          const { data } = m;
          return {
            id: data.id,
            name: data.name,
          };
        })
      );
    }
    return await Promise.all(metaPromises);
  } catch (error) {
    console.log(error);
  }
});

export const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    clearImages: (state) => {
      return initialState;
    },
    markImageForRemoval: (state, action) => {
      state.images = state.images.filter((image) => image.id != action.payload);
      state.imageIdsToDelete = state.imageIdsToDelete.concat([action.payload]);
    },
  },
  extraReducers: (builder) => {
    builder
      // сохранение фото
      .addCase(saveImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveImage.fulfilled, (state, action) => {
        state.loading = false;
        state.images.push(action.payload);
      })
      .addCase(saveImage.rejected, (state) => {
        state.loading = false;
      })
      // удаление фото
      .addCase(removeImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeImage.fulfilled, (state, action) => {
        state.loading = false;
        state.images = state.images.filter(
          (image) => image.id != action.payload.id
        );
      })
      .addCase(removeImage.rejected, (state) => {
        state.loading = false;
      })
      // получение фото
      .addCase(getImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImages.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
      })
      .addCase(getImages.rejected, (state) => {
        state.loading = false;
      })
      // удаление помеченных фото
      .addCase(removeMarkedImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeMarkedImages.fulfilled, (state) => {
        state.loading = false;
        state.imageIdsToDelete = [];
      })
      .addCase(removeMarkedImages.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { clearImages, markImageForRemoval } = imageSlice.actions;
export default imageSlice.reducer;
