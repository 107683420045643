import React, { useEffect } from "react";
import BlogItem from "../../components/BlogItem";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../../redux/features/blog/blogSlice";
import { AddButton } from "../../components/AddButton";
import { Link } from "react-router-dom";

function BlogsAdminPage() {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blog.blogs);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  return (
    <div className="max-w-[900px] mx-auto py-10">
      <div className="flex justify-between">
        <h1 className="text-3xl text-[#40627A]">Статьи</h1>
        <Link to={"/blogs/new"} className="mb-5">
          <AddButton />
        </Link>
      </div>

      {blogs.length > 0 ? (
        <div className="flex flex-col basis-4/5">
          {blogs.map((blog) => (
            <BlogItem key={blog.id} blog={blog} />
          ))}
        </div>
      ) : (
        <div className="text-xl text-center text-black py-40">
          Статьи отсутствуют.
        </div>
      )}
    </div>
  );
}

export default BlogsAdminPage;
