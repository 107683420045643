import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

function PasswordChangeAdminPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      oldPassword: undefined,
      newPassword: undefined,
      newPasswordConfirmed: undefined,
    },
    mode: "onBlur",
  });

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    try {
      if (data.newPassword === data.newPasswordConfirmed) {
        const username = user.username;
        dispatch(
          changePassword({
            username,
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
          })
        );
        reset();
      } else {
        toast("Ошибка. Пароли не совпадают");
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-1/4 h-60 mx-auto mt-40"
    >
      <h1 className="text-lg text-black text-center">Смена пароля</h1>
      <label className="text-xs text-gray-400">Логин: </label>
      <p className="text-xs text-black">{user?.username}</p>
      <label className="text-xs text-gray-400">
        Старый пароль:
        <input
          {...register("oldPassword", {
            required: "Поле обязательно для заполнения",
          })}
          name="oldPassword"
          placeholder="Введите старый пароль"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div>
          {errors.oldPassword && errors.oldPassword.message && (
            <p className="text-red-600">{errors.oldPassword.message}</p>
          )}
        </div>
      </label>
      <label className="text-xs text-gray-400">
        Новый пароль:
        <input
          {...register("newPassword", {
            required: "Поле обязательно для заполнения",
          })}
          name="newPassword"
          placeholder="Введите новый пароль"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div>
          {errors.newPassword && errors.newPassword.message && (
            <p className="text-red-600">{errors.newPassword.message}</p>
          )}
        </div>
      </label>
      <label>
        <p className="pt-2 text-xs text-gray-400">Повторите новый пароль:</p>
        <input
          {...register("newPasswordConfirmed", {
            required: "Поле обязательно для заполнения",
          })}
          name="newPasswordConfirmed"
          placeholder="Повторите новый пароль"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        {watch("newPasswordConfirmed") !== watch("newPassword") &&
        getValues("newPasswordConfirmed") ? (
          <p className="text-xs text-red-600">Пароли не совпадают</p>
        ) : null}
        <div>
          {errors.newPasswordConfirmed &&
            errors.newPasswordConfirmed.message && (
              <p className="text-xs text-red-600">
                {errors.newPasswordConfirmed.message}
              </p>
            )}
        </div>
      </label>

      <div className="flex gap-8 justify-center mt-4">
        <button
          type="submit"
          className="flex justify-center items-center text-xs bg-gray-600 text-white rounded-sm py-2 px-4"
        >
          Сменить
        </button>
      </div>
    </form>
  );
}

export default PasswordChangeAdminPage;
