import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateGame } from "../redux/features/game/gameSlice";
import axios from "../utils/axios";
import { toast } from "react-toastify";
import {
  clearImages,
  getImages,
  removeMarkedImages,
} from "../redux/features/image/imageSlice";
import Dropdown from "../components/Dropdown";
import ImageUpload from "../components/ImageUpload";
import { useForm } from "react-hook-form";

function EditGamesAdminPage() {
  const images = useSelector((state) => state.image.images);
  const [previewImg, setPreview] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [format, setFormat] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    fetchGame();
  }, []);

  const fetchGame = useCallback(async () => {
    const { data } = await axios.get(`/games/${params.id}`);
    setDifficulty(data.difficulty);
    setFormat(data.format);
    setPreview(data.previewImg);
    dispatch(getImages(data.imgIds));
    initCounters(data);
    reset(data);
  });

  const imgIds = images.map((i) => i.id);

  const onSubmit = (data) => {
    try {
      if (!previewImg) {
        toast("Загрузите фото новости");
        return;
      }

      const updatedformData = new FormData();
      updatedformData.append("id", data.id);
      updatedformData.append("title", data.title);
      updatedformData.append("difficulty", difficulty);
      updatedformData.append("format", format);
      updatedformData.append("theme", data.theme);
      updatedformData.append("purpose", data.purpose);
      updatedformData.append("target", data.target);
      updatedformData.append("description", data.description);
      updatedformData.append("complectation", data.complectation);
      updatedformData.append("price", data.price);
      updatedformData.append("previewImg", previewImg);
      updatedformData.append("imgIds", imgIds);
      dispatch(updateGame(updatedformData));
      dispatch(removeMarkedImages());
      toast("Изменения сохранены");
      navigate("/games");
    } catch (error) {
      console.log(error);
    }
  };

  const [counters, setCounters] = useState({
    title: 0,
    theme: 0,
    purpose: 0,
    target: 0,
    description: 0,
    complectation: 0,
  });

  const initCounters = (data) => {
    setCounters((prevState) => {
      return {
        title: data.title.length,
        theme: data.theme.length,
        purpose: data.purpose.length,
        target: data.target.length,
        description: data.description.length,
        complectation: data.complectation.length,
      };
    });
  };

  // счетчик символов
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCounters((prevCounters) => ({
      ...prevCounters,
      [name]: value.length,
    }));
  };

  const returnHandler = () => {
    navigate("/games");
  };

  const previewChangeHandler = (previewImg) => {
    setPreview(previewImg);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-5/12 mx-auto py-10">
      <p className="text-xl mb-5 ">Редактирование продукции</p>
      <label className="text-xs text-black opacity-70">
        Наименование игры*
        <input
          {...register("title", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите наименование игры"
          onChange={handleInputChange}
          name="title"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.title && errors.title.message && (
              <p className="text-red-600">{errors.title.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.title}/50</p>
        </div>
      </label>

      <div className="mt-3 text-xs text-black opacity-70">
        Описание игры*
        <textarea
          {...register("description", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 200,
              message:
                "Превышена максимальная длина символов (200). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите описание игры"
          rows="2"
          onChange={handleInputChange}
          name="description"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.description && errors.description.message && (
              <p className="text-red-600">{errors.description.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.description}/200</p>
        </div>
      </div>

      <div className="flex flex-wrap justify-between w-full">
        <label className="text-xs text-black opacity-70 w-5/12">
          Формат игры*
          <Dropdown
            onSelect={(format) => {
              setFormat(format);
            }}
            options={[
              { label: "Не выбрано", value: "" },
              { label: "Настольная", value: "Настольная" },
              {
                label: "Настольно-интерактивная",
                value: "Настольно-интерактивная",
              },
              { label: "Командная", value: "Командная" },
              { label: "Коммуникативная", value: "Коммуникативная" },
              { label: "Онлайн", value: "Онлайн" },
            ]}
            selected={format}
          />
          <div>
            {!format && (
              <p className="text-red-600">Поле обязательно для заполнения</p>
            )}
          </div>
        </label>

        <label className="text-xs text-black opacity-70 w-5/12">
          Уровень сложности*
          <Dropdown
            onSelect={(difficulty) => {
              setDifficulty(difficulty);
            }}
            options={[
              { label: "Не выбрано", value: "" },
              { label: "Легкая", value: "Легкая" },
              { label: "Смешанная", value: "Смешанная" },
              { label: "Сложная", value: "Сложная" },
            ]}
            selected={difficulty}
          />
          <div>
            {!difficulty && (
              <p className="text-red-600">Поле обязательно для заполнения</p>
            )}
          </div>
        </label>
      </div>

      <div className="mt-3 text-xs text-black opacity-70">
        Темы вопросов*
        <textarea
          {...register("theme", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 100,
              message:
                "Превышена максимальная длина символов (100). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите темы вопросов"
          rows="1"
          onChange={handleInputChange}
          name="theme"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.theme && errors.theme.message && (
              <p className="text-red-600">{errors.theme.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.theme}/100</p>
        </div>
      </div>

      <div className="mt-3 text-xs text-black opacity-70">
        Назначение игры*
        <textarea
          {...register("purpose", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 150,
              message:
                "Превышена максимальная длина символов (150). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите назначение игры"
          rows="1"
          onChange={handleInputChange}
          name="purpose"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.purpose && errors.purpose.message && (
              <p className="text-red-600">{errors.purpose.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.purpose}/150</p>
        </div>
      </div>

      <div className="mt-3 text-xs text-black opacity-70">
        Для кого подходит игра*
        <textarea
          {...register("target", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 250,
              message:
                "Превышена максимальная длина символов (250). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите для кого подходит игра"
          rows="2"
          onChange={handleInputChange}
          name="target"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.target && errors.target.message && (
              <p className="text-red-600">{errors.target.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.target}/250</p>
        </div>
      </div>

      <div className="mt-3 text-xs text-black opacity-70">
        Содержимое игры*
        <textarea
          {...register("complectation", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 500,
              message:
                "Превышена максимальная длина символов (500). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите содержимое игры"
          rows="5"
          onChange={handleInputChange}
          name="complectation"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.complectation && errors.complectation.message && (
              <p className="text-red-600">{errors.complectation.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.complectation}/500</p>
        </div>
      </div>

      <label className="text-xs text-black opacity-70">
        Стоимость*
        <input
          {...register("price", {
            required: "Поле обязательно для заполнения",
          })}
          placeholder="Введите стоимость"
          onChange={handleInputChange}
          name="price"
          type="number"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.price && errors.price.message && (
              <p className="text-red-600">{errors.price.message}</p>
            )}
          </div>
        </div>
      </label>

      <ImageUpload
        preview={previewImg}
        onSelect={previewChangeHandler}
        delayedRemove={true}
        multipleImages={true}
      />

      <div className="flex gap-8 items-center justify-end mt-4">
        <button
          type="submit"
          className="flex justify-center items-center bg-green-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Сохранить изменения
        </button>

        <button
          onClick={returnHandler}
          className=" flex justify-center items-center bg-red-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Отменить
        </button>
      </div>
    </form>
  );
}

export default EditGamesAdminPage;
