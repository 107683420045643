import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearImages,
  removeImage,
  saveImage,
  markImageForRemoval,
} from "../redux/features/image/imageSlice";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";

function ImageUpload(props) {
  const images = useSelector((state) => state.image.images);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearImages());
  }, []);

  // добавление нескольких фото и проверка на дубликат, чтобы не добавляли одинаковые фото по несколько раз
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (props.multipleImages) {
      // добавление нескольких фотографий
      selectedFiles.forEach((file) => {
        const isDuplicate = images.some((i) => i.name === file.name);

        if (isDuplicate) {
          toast("Фото уже добавлено");
        } else {
          dispatch(saveImage(file));
        }
      });
    } else {
      // добавление одной фотографии
      const file = selectedFiles[0];
      if (images.length > 0) {
        handleImageRemove(images[0].id);
      }
      dispatch(saveImage(file));
    }

    e.target.value = "";
  };

  // удаление фото
  const handleImageRemove = (id) => {
    if (props.delayedRemove) {
      dispatch(markImageForRemoval(id));
    } else {
      dispatch(removeImage(id));
    }
  };

  // вручную изменить превью
  const handlePreviewChange = (e) => {
    props.onSelect(e.target.value);
  };

  // useEffect вызывается каждый раз, когда происходит изменение в массиве images
  // если массив пустой, то возвращается пустой oldPreview
  // если раньше массив был пустой или в массиве перестал присутствовать id из oldPreview (то есть фотография была удалена),
  // то oldPreview присваивается id первого элемента массива
  useEffect(() => {
    props.onSelect((oldPreview) => {
      if (images.length === 0) {
        return "";
      } else if (
        oldPreview === "" ||
        images.every((image) => image.id !== oldPreview)
      ) {
        return images[0].id;
      } else {
        return oldPreview;
      }
    });
  }, [images]);

  return (
    <div className="mt-4 mb-4">
      <label className="text-gray-300 py-2 bg-gray-600 text-xs mt-2 flex items-center justify-center border-2 border-dotted cursor-pointer">
        Прикрепить фото:
        <input
          type="file"
          className="hidden"
          multiple={props.multipleImages}
          onChange={handleImageChange}
        />
      </label>
      <div className="flex flex-wrap object-cover py-2 -mx-2 -my-2">
        {images.map((image) => (
          <div key={image.id} className="relative w-1/4 px-2 py-2">
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${image.id}`}
              alt={image.name}
              className="h-32 mr-2 mb-1"
            />
            <button
              onClick={() => handleImageRemove(image.id)}
              className="absolute top-0 right-0 w-6 h-6 bg-white rounded-full flex items-center justify-center border border-gray-500"
            >
              <AiFillDelete className=" text-gray-500 hover:text-red-500 focus:text-red-500" />
            </button>
            <label className="text-center text-xs">
              <input
                type="radio"
                value={image.id}
                name="isPreview"
                checked={image.id === props.preview}
                onChange={handlePreviewChange}
                className="mr-1"
              />
              Сделать главной
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageUpload;
