import React, { useState } from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

function SliderPhoto(props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const isFirstSlide = prevIndex === 0;
      return isFirstSlide ? props.imageArray.length - 1 : prevIndex - 1;
    });
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const isLastSlide = prevIndex === props.imageArray.length - 1;
      return isLastSlide ? 0 : prevIndex + 1;
    });
  };

  return (
    <div className="max-w-[250px] h-[250px] w-full relative group">
      <div
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/api/images/${props.imageArray[currentIndex]})`,
        }}
        className="w-full h-full bg-center bg-cover"
      ></div>
      <div
        className={`hidden ${
          props.imageArray.length === 1 ? "" : "group-hover:block"
        } absolute to-[50%] -translate-x-0 translate-y-[-100%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer`}
      >
        <AiFillCaretLeft onClick={prevSlide} size={30} />
      </div>
      <div
        className={`hidden ${
          props.imageArray.length === 1 ? "" : "group-hover:block"
        } absolute to-[50%] -translate-x-0 translate-y-[-100%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer`}
      >
        <AiFillCaretRight onClick={nextSlide} size={30} />
      </div>
    </div>
  );
}

export default SliderPhoto;
