import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { checkIsAuth, logout } from "../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";

function NavbarAdmin() {
  const isAuth = useSelector(checkIsAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const logoutHandler = () => {
    dispatch(logout());
    window.localStorage.removeItem("token");
    navigate("/login");
    toast("Вы вышли из системы");
  };

  return (
    <div className="flex py-4 px-8 justify-between items-center bg-[#40627A]">
      <Link to="/games" className="flex">
        {" "}
        <img src={logo} alt="/" />{" "}
      </Link>

      {isAuth && (
        <ul className="flex gap-16">
          <li>
            <NavLink
              to="/games"
              className={`text-s text-white ${
                location.pathname === "/games" ? "underline" : "hover:underline"
              }`}
            >
              Продукция
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/mentors"
              className={`text-s text-white ${
                location.pathname === "/mentors"
                  ? "underline"
                  : "hover:underline"
              }`}
            >
              Состав команды
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/events"
              className={`text-s text-white ${
                location.pathname === "/events"
                  ? "underline"
                  : "hover:underline"
              }`}
            >
              Мероприятия
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/news"
              className={`text-s text-white ${
                location.pathname === "/news" ? "underline" : "hover:underline"
              }`}
            >
              Новости
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/blogs"
              className={`text-s text-white ${
                location.pathname === "/blogs" ? "underline" : "hover:underline"
              }`}
            >
              Блог
            </NavLink>
          </li>
          <li>
            <NavLink
              className="text-s text-white hover:underline"
              to="https://docs.google.com/spreadsheets/d/1lUqkiecPnAmNZ3cifC_1-eu9dGEsSBG8G7q5vU4cbfI/edit#gid=0"
            >
              Заказы
            </NavLink>
          </li>
        </ul>
      )}

      <div className="flex flex-col gap-3 items-center">
        <div className="flex justify-between items-center bg-white text-s text-[#40627A] rounded-sm px-4 py-2 max-w-[82px]">
          {isAuth ? (
            <button onClick={logoutHandler}>Выйти</button>
          ) : (
            <Link to="/login"> Войти </Link>
          )}
        </div>
        {isAuth && (
          <Link className="text-white hover:underline text-xs" to="/security">
            {" "}
            Изменить пароль{" "}
          </Link>
        )}
      </div>
    </div>
  );
}

export default NavbarAdmin;
