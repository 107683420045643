import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createMentor } from "../../redux/features/mentor/mentorSlice";
import { toast } from "react-toastify";
import ImageUpload from "../../components/ImageUpload";
import { useForm } from "react-hook-form";

function AddMentorsAdminPage() {
  const [previewImg, setPreview] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: {
      fullName: "",
      role: "",
      otherRole: "",
      jobTitle: "",
      direction: "",
      experience: "",
      education: "",
      laborActivity: "",
    },
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    try {
      if (!previewImg) {
        toast("Загрузите фото члена команды");
        return;
      }

      const formData = new FormData();
      formData.append("fullName", data.fullName);
      formData.append("role", data.role);

      if (data.role === "Другое") {
        formData.append("otherRole", data.otherRole);
      } else {
        formData.append("otherRole", "");
      }

      formData.append("jobTitle", data.jobTitle);
      formData.append("direction", data.direction);
      formData.append("experience", data.experience);
      formData.append("education", data.education);
      formData.append("laborActivity", data.laborActivity);
      formData.append("previewImg", previewImg);
      dispatch(createMentor(formData));
      toast("Член команды добавлен");
      navigate("/mentors");
    } catch (error) {
      console.log(error);
    }
  };

  const [counters, setCounters] = useState({
    fullName: 0,
    jobTitle: 0,
    direction: 0,
    experience: 0,
    education: 0,
    laborActivity: 0,
  });

  // счетчик символов
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCounters((prevCounters) => ({
      ...prevCounters,
      [name]: value.length,
    }));
  };

  const validateHandler = (value) => {
    return getValues("role") !== "Другое" || value !== "";
  };

  const returnHandler = () => {
    navigate("/mentors");
  };

  const [isOtherRoleVisible, setOtherRoleVisible] = useState(false);
  const handleRadioClick = () => {
    if (document.getElementById("other").checked) {
      setOtherRoleVisible(true);
    } else {
      setOtherRoleVisible(false);
    }
    clearErrors("otherRole");
  };

  const previewChangeHandler = (preview) => {
    setPreview(preview);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-5/12 mx-auto py-10">
      <p className="text-xl mb-5 ">Добавление нового члена команды</p>
      <label className="text-xs text-black opacity-70">
        ФИО*
        <input
          {...register("fullName", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите ФИО"
          onChange={handleInputChange}
          name="fullName"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.fullName && errors.fullName.message && (
              <p className="text-red-600">{errors.fullName.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.fullName}/50</p>
        </div>
      </label>

      <div className="text-xs text-black opacity-70 mt-2">
        Тип роли в проекте*
        <div className="flex pb-3 gap-2 h-6 mt-2 items-center">
          <input
            {...register("role", { required: true })}
            type="radio"
            id="mentor"
            onClick={handleRadioClick}
            value="Наставник"
          />
          <label htmlFor="mentor">Наставник</label>
        </div>
        <div className="flex pb-2 gap-2 h-6 items-center">
          <input
            {...register("role")}
            type="radio"
            id="orientolog"
            onClick={handleRadioClick}
            value="Профориентолог"
          />
          <label htmlFor="orientolog">Профориентолог</label>
        </div>
        <div className="flex gap-2 h-6">
          <div className="flex gap-2 items-center">
            <input
              {...register("role")}
              type="radio"
              onClick={handleRadioClick}
              id="other"
              value="Другое"
            />
            <label htmlFor="other">Другое</label>
          </div>
          {isOtherRoleVisible && (
            <input
              {...register("otherRole", {
                validate: { validateHandler },
              })}
              placeholder="Введите роль"
              id="otherRole"
              className="text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500"
            />
          )}
          {errors.otherRole && (
            <p
              className={`${
                isOtherRoleVisible ? "visible" : "hidden"
              } text-red-600`}
            >
              Поле обязательно для заполнения
            </p>
          )}
        </div>
        {errors.role && (
          <p className="text-red-600">Поле обязательно для заполнения</p>
        )}
      </div>
      <div className="mt-5 text-xs text-black opacity-70">
        Должность*
        <textarea
          {...register("jobTitle", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 200,
              message:
                "Превышена максимальная длина символов (200). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите должность"
          rows="2"
          onChange={handleInputChange}
          name="jobTitle"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.jobTitle && errors.jobTitle.message && (
              <p className="text-red-600">{errors.jobTitle.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.jobTitle}/200</p>
        </div>
      </div>

      <label className="text-xs text-black opacity-70">
        Направления*
        <textarea
          {...register("direction", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 200,
              message:
                "Превышена максимальная длина символов (200). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите направления"
          rows="2"
          onChange={handleInputChange}
          name="direction"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.direction && errors.direction.message && (
              <p className="text-red-600">{errors.direction.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.direction}/200</p>
        </div>
      </label>
      <label className="text-xs text-black opacity-70">
        Стаж профильной работы*
        <input
          {...register("experience", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите стаж профильной работы"
          onChange={handleInputChange}
          name="experience"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.experience && errors.experience.message && (
              <p className="text-red-600">{errors.experience.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.experience}/50</p>
        </div>
      </label>
      <label className="text-xs text-black opacity-70">
        Образование*
        <textarea
          {...register("education", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 200,
              message:
                "Превышена максимальная длина символов (200). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите образование"
          rows="2"
          onChange={handleInputChange}
          name="education"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.education && errors.education.message && (
              <p className="text-red-600">{errors.education.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.education}/200</p>
        </div>
      </label>
      <label className="text-xs text-black opacity-70">
        Трудовая деятельность*
        <textarea
          {...register("laborActivity", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 500,
              message:
                "Превышена максимальная длина символов (500). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите трудовую деятельность"
          rows="5"
          onChange={handleInputChange}
          name="laborActivity"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.laborActivity && errors.laborActivity.message && (
              <p className="text-red-600">{errors.laborActivity.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.laborActivity}/500</p>
        </div>
      </label>

      <ImageUpload
        preview={previewImg}
        onSelect={previewChangeHandler}
        delayedRemove={false}
        multipleImages={false}
      />

      <div className="flex gap-8 items-center justify-end mt-4">
        <button
          type="submit"
          className="flex justify-center items-center bg-green-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Добавить члена команды
        </button>

        <button
          onClick={returnHandler}
          className=" flex justify-center items-center bg-red-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Отменить
        </button>
      </div>
    </form>
  );
}

export default AddMentorsAdminPage;
