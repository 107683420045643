import React from "react";
import NavbarAdmin from "./NavbarAdmin";
import { Outlet } from "react-router-dom";
import ProtectedWrapper from "../utils/ProtectedWrapper";

function LayoutAdmin() {
  return (
    <React.Fragment>
      <NavbarAdmin />
      <ProtectedWrapper>
        <Outlet />
      </ProtectedWrapper>
    </React.Fragment>
  );
}

export default LayoutAdmin;
