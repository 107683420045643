import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createBlog } from "../../redux/features/blog/blogSlice";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

function AddBlogsAdminPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      postNumber: "",
      postName: "",
    },
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("postNumber", data.postNumber);
      formData.append("postName", data.postName);
      dispatch(createBlog(formData));
      toast("Пост добавлен");
      navigate("/blogs");
    } catch (error) {
      console.log(error);
    }
  };

  const returnHandler = () => {
    navigate("/blogs");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-5/12 mx-auto py-10">
      <p className="text-xl mb-5 ">Добавление новой статьи</p>
      <label className="text-xs text-black opacity-70">
        Название статьи*
        <input
          {...register("title", {
            required: "Поле обязательно для заполнения",
          })}
          placeholder="Введите название статьи"
          name="title"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          {errors.title && errors.title.message && (
            <p className="text-red-600">{errors.title.message}</p>
          )}
        </div>
      </label>

      <label className="text-xs text-black opacity-70">
        Номер статьи*
        <input
          {...register("postNumber", {
            required: "Поле обязательно для заполнения",
          })}
          placeholder="Введите номер статьи"
          name="postNumber"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          {errors.postNumber && errors.postNumber.message && (
            <p className="text-red-600">{errors.postNumber.message}</p>
          )}
        </div>
      </label>

      <label className="text-xs text-black opacity-70">
        Ссылка на статью*
        <input
          {...register("postName", {
            required: "Поле обязательно для заполнения",
          })}
          placeholder="Введите ссылку на статью (@...)"
          name="postName"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          {errors.postName && errors.postName.message && (
            <p className="text-red-600">{errors.postName.message}</p>
          )}
        </div>
      </label>

      <div className="flex gap-8 items-center justify-end mt-4">
        <button
          type="submit"
          className="flex justify-center items-center bg-green-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Добавить статью
        </button>

        <button
          onClick={returnHandler}
          className=" flex justify-center items-center bg-red-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Отменить
        </button>
      </div>
    </form>
  );
}

export default AddBlogsAdminPage;
