import React, { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateNews } from "../../redux/features/news/newsSlice";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import ImageUpload from "../../components/ImageUpload";
import { useForm } from "react-hook-form";
import {
  getImages,
  removeMarkedImages,
} from "../../redux/features/image/imageSlice";
import { useEffect } from "react";

function EditNewsAdminPage() {
  const images = useSelector((state) => state.image.images);
  const [previewImg, setPreview] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = useCallback(async () => {
    const { data } = await axios.get(`/news/${params.id}`);
    setPreview(data.previewImg);
    dispatch(getImages(data.imgIds));
    initCounters(data);
    reset(data);
  });

  const imgIds = images.map((i) => i.id);

  const onSubmit = (data) => {
    try {
      if (!previewImg) {
        toast("Загрузите фото новости");
        return;
      }

      const updatedformData = new FormData();
      updatedformData.append("id", data.id);
      updatedformData.append("title", data.title);
      updatedformData.append("smallDescription", data.smallDescription);
      updatedformData.append("firstSubtitle", data.firstSubtitle);
      updatedformData.append(
        "firstSubtitleDescription",
        data.firstSubtitleDescription
      );
      updatedformData.append("secondSubtitle", data.secondSubtitle);
      updatedformData.append(
        "secondSubtitleDescription",
        data.secondSubtitleDescription
      );
      updatedformData.append("thirdSubtitle", data.thirdSubtitle);
      updatedformData.append(
        "thirdSubtitleDescription",
        data.thirdSubtitleDescription
      );
      updatedformData.append("fourthSubtitle", data.fourthSubtitle);
      updatedformData.append(
        "fourthSubtitleDescription",
        data.fourthSubtitleDescription
      );
      updatedformData.append("fifthSubtitle", data.fifthSubtitle);
      updatedformData.append(
        "fifthSubtitleDescription",
        data.fifthSubtitleDescription
      );
      updatedformData.append("previewImg", previewImg);
      updatedformData.append("imgIds", imgIds);
      dispatch(updateNews(updatedformData));
      dispatch(removeMarkedImages());
      toast("Изменения сохранены");
      navigate("/news");
    } catch (error) {
      console.log(error);
    }
  };

  const [counters, setCounters] = useState({
    title: 0,
    smallDescription: 0,
    firstSubtitle: 0,
    firstSubtitleDescription: 0,
    secondSubtitle: 0,
    secondSubtitleDescription: 0,
    thirdSubtitle: 0,
    thirdSubtitleDescription: 0,
    fourthSubtitle: 0,
    fourthSubtitleDescription: 0,
    fifthSubtitle: 0,
    fifthSubtitleDescription: 0,
  });

  const initCounters = (data) => {
    setCounters((prevState) => {
      return {
        title: data.title.length,
        smallDescription: data.smallDescription.length,
        firstSubtitle: data.firstSubtitle.length,
        firstSubtitleDescription: data.firstSubtitleDescription.length,
        secondSubtitle: data.secondSubtitle.length,
        secondSubtitleDescription: data.secondSubtitleDescription.length,
        thirdSubtitle: data.thirdSubtitle.length,
        thirdSubtitleDescription: data.thirdSubtitleDescription.length,
        fourthSubtitle: data.fourthSubtitle.length,
        fourthSubtitleDescription: data.fourthSubtitleDescription.length,
        fifthSubtitle: data.fifthSubtitle.length,
        fifthSubtitleDescription: data.fifthSubtitleDescription.length,
      };
    });
  };

  // счетчик символов
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCounters((prevCounters) => ({
      ...prevCounters,
      [name]: value.length,
    }));
  };

  const returnHandler = () => {
    navigate("/news");
  };

  const previewChangeHandler = (previewImg) => {
    setPreview(previewImg);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-5/12 mx-auto py-10">
      <p className="text-xl mb-5 ">Редактирование новость</p>
      <label className="text-xs text-black opacity-70">
        Заголовок новости*
        <input
          {...register("title", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите заголовок новости"
          onChange={handleInputChange}
          name="title"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.title && errors.title.message && (
              <p className="text-red-600">{errors.title.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.title}/50</p>
        </div>
      </label>

      <div className="mt-3 text-xs text-black opacity-70">
        Краткое описание*
        <textarea
          {...register("smallDescription", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 200,
              message:
                "Превышена максимальная длина символов (200). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите краткое описание новости"
          rows="2"
          onChange={handleInputChange}
          name="smallDescription"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.smallDescription && errors.smallDescription.message && (
              <p className="text-red-600">{errors.smallDescription.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">
            {counters.smallDescription}/200
          </p>
        </div>
      </div>

      <label className="text-xs text-black opacity-70">
        Подзаголовок новости №1*
        <input
          {...register("firstSubtitle", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите подзаголовок новости №1"
          onChange={handleInputChange}
          name="firstSubtitle"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.firstSubtitle && errors.firstSubtitle.message && (
              <p className="text-red-600">{errors.firstSubtitle.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.firstSubtitle}/50</p>
        </div>
      </label>

      <div className="mt-3 text-xs text-black opacity-70">
        Описание к подзаголовку №1*
        <textarea
          {...register("firstSubtitleDescription", {
            required: "Поле обязательно для заполнения",
            maxLength: {
              value: 2000,
              message:
                "Превышена максимальная длина символов (2000). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите описание к подзаголовку №1"
          rows="10"
          onChange={handleInputChange}
          name="firstSubtitleDescription"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.firstSubtitleDescription &&
              errors.firstSubtitleDescription.message && (
                <p className="text-red-600">
                  {errors.firstSubtitleDescription.message}
                </p>
              )}
          </div>
          <p className="text-xs text-gray-500">
            {counters.firstSubtitleDescription}/2000
          </p>
        </div>
      </div>

      <label className="text-xs text-black opacity-70">
        Подзаголовок новости №2
        <input
          {...register("secondSubtitle", {
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите подзаголовок новости №2"
          onChange={handleInputChange}
          name="secondSubtitle"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.secondSubtitle && errors.secondSubtitle.message && (
              <p className="text-red-600">{errors.secondSubtitle.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.secondSubtitle}/50</p>
        </div>
      </label>

      <div className="mt-3 text-xs text-black opacity-70">
        Описание к подзаголовку №2
        <textarea
          {...register("secondSubtitleDescription", {
            maxLength: {
              value: 2000,
              message:
                "Превышена максимальная длина символов (2000). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите описание к подзаголовку №2"
          rows="10"
          onChange={handleInputChange}
          name="secondSubtitleDescription"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.secondSubtitleDescription &&
              errors.secondSubtitleDescription.message && (
                <p className="text-red-600">
                  {errors.secondSubtitleDescription.message}
                </p>
              )}
          </div>
          <p className="text-xs text-gray-500">
            {counters.secondSubtitleDescription}/2000
          </p>
        </div>
      </div>

      <label className="text-xs text-black opacity-70">
        Подзаголовок новости №3
        <input
          {...register("thirdSubtitle", {
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите подзаголовок новости №3"
          onChange={handleInputChange}
          name="thirdSubtitle"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.thirdSubtitle && errors.thirdSubtitle.message && (
              <p className="text-red-600">{errors.thirdSubtitle.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.thirdSubtitle}/50</p>
        </div>
      </label>

      <div className="mt-3 text-xs text-black opacity-70">
        Описание к подзаголовку №3
        <textarea
          {...register("thirdSubtitleDescription", {
            maxLength: {
              value: 2000,
              message:
                "Превышена максимальная длина символов (2000). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите описание к подзаголовку №3"
          rows="10"
          onChange={handleInputChange}
          name="thirdSubtitleDescription"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.thirdSubtitleDescription &&
              errors.thirdSubtitleDescription.message && (
                <p className="text-red-600">
                  {errors.thirdSubtitleDescription.message}
                </p>
              )}
          </div>
          <p className="text-xs text-gray-500">
            {counters.thirdSubtitleDescription}/2000
          </p>
        </div>
      </div>

      <label className="text-xs text-black opacity-70">
        Подзаголовок новости №4
        <input
          {...register("fourthSubtitle", {
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите подзаголовок новости №4"
          onChange={handleInputChange}
          name="fourthSubtitle"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.fourthSubtitle && errors.fourthSubtitle.message && (
              <p className="text-red-600">{errors.fourthSubtitle.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.fourthSubtitle}/50</p>
        </div>
      </label>

      <div className="mt-3 text-xs text-black opacity-70">
        Описание к подзаголовку №4
        <textarea
          {...register("fourthSubtitleDescription", {
            maxLength: {
              value: 2000,
              message:
                "Превышена максимальная длина символов (2000). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите описание к подзаголовку №4"
          rows="10"
          onChange={handleInputChange}
          name="fourthSubtitleDescription"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.fourthSubtitleDescription &&
              errors.fourthSubtitleDescription.message && (
                <p className="text-red-600">
                  {errors.fourthSubtitleDescription.message}
                </p>
              )}
          </div>
          <p className="text-xs text-gray-500">
            {counters.fourthSubtitleDescription}/2000
          </p>
        </div>
      </div>

      <label className="text-xs text-black opacity-70">
        Подзаголовок новости №5
        <input
          {...register("fifthSubtitle", {
            maxLength: {
              value: 50,
              message:
                "Превышена максимальная длина символов (50). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите подзаголовок новости №5"
          onChange={handleInputChange}
          name="fifthSubtitle"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.fifthSubtitle && errors.fifthSubtitle.message && (
              <p className="text-red-600">{errors.fifthSubtitle.message}</p>
            )}
          </div>
          <p className="text-xs text-gray-500">{counters.fifthSubtitle}/50</p>
        </div>
      </label>

      <div className="mt-3 text-xs text-black opacity-70">
        Описание к подзаголовку №5
        <textarea
          {...register("fifthSubtitleDescription", {
            maxLength: {
              value: 2000,
              message:
                "Превышена максимальная длина символов (2000). Пожалуйста, сократите текст",
            },
          })}
          placeholder="Введите описание к подзаголовку №5"
          rows="10"
          onChange={handleInputChange}
          name="fifthSubtitleDescription"
          className={`mt-1 text-black w-full rounded-lg border py-1 px-2 text-xs outline-none placeholder:text-gray-500`}
        />
        <div className="flex justify-between">
          <div>
            {errors.fifthSubtitleDescription &&
              errors.fifthSubtitleDescription.message && (
                <p className="text-red-600">
                  {errors.fifthSubtitleDescription.message}
                </p>
              )}
          </div>
          <p className="text-xs text-gray-500">
            {counters.fifthSubtitleDescription}/2000
          </p>
        </div>
      </div>

      <ImageUpload
        preview={previewImg}
        onSelect={previewChangeHandler}
        delayedRemove={true}
        multipleImages={true}
      />

      <div className="flex gap-8 items-center justify-end mt-4">
        <button
          type="submit"
          className="flex justify-center items-center bg-green-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Сохранить изменения
        </button>

        <button
          onClick={returnHandler}
          className=" flex justify-center items-center bg-red-600 text-xs text-white rounded-sm py-2 px-4"
        >
          Отменить
        </button>
      </div>
    </form>
  );
}

export default EditNewsAdminPage;
