// это расширение необхожимо для того,чтобы отсылать запросы
import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
});

// для проверки токена (который мы получаем при авторизации)
instance.interceptors.request.use((config) => {
  config.headers.Authorization = window.localStorage.getItem("token");

  return config;
});

export default instance;
