import React, { useEffect } from "react";
import EventItem from "../../components/EventItem";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents } from "../../redux/features/event/eventSlice";
import { AddButton } from "../../components/AddButton";
import { Link } from "react-router-dom";

function EventsAdminPage() {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.event.events);

  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  return (
    <div className="max-w-[900px] mx-auto py-10">
      <div className="flex justify-between">
        <h1 className="text-3xl text-[#40627A]">Мероприятия</h1>
        <Link to={"/events/new"} className="mb-5">
          <AddButton />
        </Link>
      </div>

      {events.length > 0 ? (
        <div className="flex flex-col basis-4/5">
          {events.map((event) => (
            <EventItem key={event.id} event={event} />
          ))}
        </div>
      ) : (
        <div className="text-xl text-center text-black py-40">
          Мероприятия отсутствуют.
        </div>
      )}
    </div>
  );
}

export default EventsAdminPage;
